import React, { useState } from 'react';
import { jsPDF } from 'jspdf';

function SummaryOutput({ text = '', summary = '', nouns = 0, verbs = 0, adjectives = 0, clearText }) {
    // Zustand für Bild hinzufügen
    const [image, setImage] = useState(null);

    // Verbesserte Muster für Nomen, Verben und Adjektive
    const nounPattern = /^[A-ZÄÖÜ][a-zäöüß]+(ung|heit|keit|tion|ismus|ist|er|in|en|ment|tum|nis|e|s)?$/;
    const verbPattern = /(^[a-zäöüß]+en$|ern$|ieren$|te$|est$|et$|te$|ten$|end$)/;
    const adjectivePattern = /(ig$|lich$|isch$|haft$|bar$|los$|end$|er|e|en)$/;

    // Text verarbeiten und nur Nomen, Verben und Adjektive anklickbar machen
    const processedText = text.trim().split(/\s+/).map((word, index) => {
        const isNoun = nounPattern.test(word);
        const isVerb = verbPattern.test(word.toLowerCase());
        const isAdjective = adjectivePattern.test(word.toLowerCase());

        const isSearchable = isNoun || isVerb || isAdjective;

        return (
            <span key={index} style={{ marginRight: '5px' }}>
                {isSearchable ? (
                    <a
                        href={`https://www.google.com/search?q=${word}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="foreign-word-link"
                    >
                        {word}
                    </a>
                ) : (
                    word
                )}
                {' '}
            </span>
        );
    });

    const exportToPDF = () => {
        const doc = new jsPDF();

        // Titel hinzufügen
        doc.text("Zusammenfassung der Analyse", 10, 10);

        // Füge die Zusammenfassung als ordnungsgemäß formatierter Text hinzu
        const summaryText = doc.splitTextToSize(summary, 180); // Teilt den Text in Zeilen von max 180px Breite auf
        doc.text(summaryText, 10, 20); // Fügt den formatierten Text hinzu, beginnend bei y=20

        // Speichere das PDF-Dokument
        doc.save("Zusammenfassung.pdf");
    };

    // Funktion zum Löschen des Textes und Bildes
    const handleClear = () => {
        clearText();
        setImage(null); // Bild löschen
    };

    return (
        <div className="output-container">
            <h2>Textinhalt</h2>
            <div className="output-box">
                {processedText}
            </div>
            <h3>Zusammenfassung</h3>
            <div className="summary-box">
                <p>{summary}</p>
                <p><strong>Nomen:</strong> {nouns}</p>
                <p><strong>Verben:</strong> {verbs}</p>
                <p><strong>Adjektive:</strong> {adjectives}</p>
            </div>

            {/* Bildvorschau, wenn ein Bild vorhanden ist */}
            {image && (
                <div style={{ marginTop: '20px' }}>
                    <h3>Aufgenommenes Bild</h3>
                    <img src={image} alt="Captured" style={{ width: "300px", height: "auto" }} />
                </div>
            )}

            <div className="button-container">
                <button onClick={handleClear} className="pdf-button">Löschen</button>
                <button onClick={exportToPDF} className="pdf-button">Export als PDF</button>
            </div>
        </div>
    );
}

export default SummaryOutput;
