import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { performOCR } from './ocr';

function OCRComponent({ onTextExtracted }) {
    const webcamRef = useRef(null);
    const [error, setError] = useState('');
    const [facingMode, setFacingMode] = useState('user'); 

    const capture = async () => {
        try {
            const imageSrc = webcamRef.current.getScreenshot();
            const extractedText = await performOCR(imageSrc);
            onTextExtracted(extractedText);
        } catch (e) {
            setError('Fehler beim Erfassen des Bildes.');
            console.error('Kamera-Fehler:', e);
        }
    };

    const toggleCamera = () => {
        setFacingMode((prevMode) => (prevMode === 'user' ? 'environment' : 'user'));
    };

    return (
        <div className="ocr-container">
            {error && <p className="error-message">{error}</p>}
            <Webcam
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={{ facingMode }}
                className="webcam-feed" // Hier wird die Klasse hinzugefügt
                onUserMediaError={() => setError('Zugriff auf die Kamera verweigert oder nicht verfügbar.')}
            />
            <button onClick={capture} className="pdf-button">Text extrahieren</button>
            <button onClick={toggleCamera} className="pdf-button">
                {facingMode === 'user' ? 'Wechsel zu Rückkamera' : 'Wechsel zu Frontkamera'}
            </button>
        </div>
    );
}

export default OCRComponent;
