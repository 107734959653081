import React from 'react';

function TextInput({ text, onTextChange }) {
    return (
        <div style={{ marginBottom: '20px' }}>
            <textarea
                className="text-input"
                value={text}
                onChange={(e) => onTextChange(e.target.value)} // Korrekt: Aufruf von onTextChange
                placeholder="Geben Sie hier Ihren Text ein..."
            />
        </div>
    );
}

export default TextInput;
