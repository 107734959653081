// FileUploadComponent.js
import React, { useState } from 'react';
import { performOCR } from './ocr';

function FileUploadComponent({ onTextExtracted }) {
    const [error, setError] = useState('');

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onload = async () => {
                try {
                    const imageSrc = reader.result;
                    const extractedText = await performOCR(imageSrc);
                    onTextExtracted(extractedText);
                } catch (e) {
                    setError('Fehler beim Verarbeiten des Bildes.');
                    console.error('OCR-Fehler:', e);
                }
            };
            reader.readAsDataURL(file);
        } else {
            setError('Bitte eine gültige Bilddatei hochladen.');
        }
    };

    return (
        <div className="file-upload-container">
            {error && <p className="error-message">{error}</p>}
            <input
                type="file"
                accept="image/*"
                onChange={handleFileUpload}
                className="file-upload-input"
                id="file-upload"
            />
            <label htmlFor="file-upload" className="custom-upload-button">
                Bild hochladen
            </label>
        </div>
    );
}

export default FileUploadComponent;
