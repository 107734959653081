import { useEffect, useState } from 'react';
import compromise from 'compromise';
import axios from 'axios';

// Schlüsselwörter für thematische Cluster, erweitert für Umwelt- und Nachhaltigkeitsthemen
const topicKeywords = {
    environment: ["Natur", "Umwelt", "Klimawandel", "nachhaltig", "Biodiversität", "Ressourcen", "Erhalt"],
    society: ["Gesellschaft", "Klimaschutz", "Lebensraum", "Gesundheit", "Lebewesen", "Nachhaltigkeit", "Umweltschutz"]
};

// Funktion zur Textzusammenfassung mit Hugging Face API
async function fetchSummaryFromAPI(text) {
    const apiKey = process.env.REACT_APP_HUGGING_FACE_API_KEY;
    const modelUrl = 'https://api-inference.huggingface.co/models/facebook/bart-large-cnn';

    try {
        console.log("Senden der API-Anfrage mit Text:", text); // Debug-Ausgabe
        const response = await axios.post(
            modelUrl,
            { inputs: text },
            {
                headers: {
                    Authorization: `Bearer ${apiKey}`,
                    'Content-Type': 'application/json',
                },
            }
        );
        console.log("API-Antwort empfangen:", response.data); // Debug-Ausgabe
        return response.data[0].summary_text;
    } catch (error) {
        console.error('Fehler bei der Textzusammenfassung:', error);
        return "Zusammenfassung konnte nicht generiert werden.";
    }
}

/**
 * React Hook to perform text analysis on a given input.
 * @param {string} text - Der zu analysierende Text.
 * @returns {Object} - Zusammenfassung, Satzanzahl, häufigste Nomen und Wortartenanalyse.
 */
export function useAnalyzeText(text) {
    const [analysis, setAnalysis] = useState({ summary: '', sentenceCount: 0, topNouns: {}, nouns: 0, verbs: 0, adjectives: 0 });

    useEffect(() => {
        async function performAnalysis() {
            const summary = await fetchSummaryFromAPI(text); // Zusammenfassung von der API abrufen
            const localAnalysis = analyzeText(text);
            setAnalysis({ ...localAnalysis, summary });
        }
        performAnalysis();
    }, [text]);

    return analysis;
}

/**
 * Hauptfunktion zur Analyse des Textes und zur Erstellung einer detaillierteren Zusammenfassung.
 * @param {string} text - Der zu analysierende Text.
 * @returns {Object} - Zusammenfassung, Satzanzahl, häufigste Nomen und Wortartenanalyse.
 */
function analyzeText(text) {
    const sentences = text.match(/[^.!?]*[.!?]/g) || [];
    const sentenceCount = sentences.length;

    // Setze einen niedrigeren Relevanz-Schwellenwert, um mehr Sätze in die Zusammenfassung aufzunehmen
    const relevanceThreshold = sentenceCount < 5 ? 1 : 2; // Angepasst für detailliertere Zusammenfassung

    const sentenceScores = sentences.map((sentence, index) => ({
        sentence,
        score: calculateRelevanceScore(sentence, index, sentenceCount)
    }));

    // Filtert Sätze, die den neuen niedrigeren Relevanz-Schwellenwert erfüllen
    const summarySentences = sentenceScores
        .filter(item => item.score >= relevanceThreshold)
        .sort((a, b) => b.score - a.score)
        .map(item => item.sentence);

    const summary = summarySentences.join(' ');

    return {
        summary: summary || "Keine relevanten Informationen gefunden.",
        sentenceCount,
        topNouns: countNouns(text),
        ...countWordTypes(text)
    };
}

/**
 * Berechnet die Relevanzbewertung eines Satzes basierend auf thematischen Clustern und Position im Text.
 * @param {string} sentence - Der zu analysierende Satz.
 * @param {number} index - Die Position des Satzes im Text.
 * @param {number} sentenceCount - Gesamtanzahl der Sätze im Text.
 * @returns {number} - Relevanzbewertung des Satzes.
 */
function calculateRelevanceScore(sentence, index, sentenceCount) {
    const lowerSentence = sentence.toLowerCase();

    const keywordCounts = Object.values(topicKeywords).map(keywords =>
        keywords.reduce((count, keyword) => count + (lowerSentence.includes(keyword.toLowerCase()) ? 1 : 0), 0)
    );

    const totalKeywordCount = keywordCounts.reduce((a, b) => a + b, 0);
    const uniqueKeywordCount = keywordCounts.filter(count => count > 0).length;

    const positionWeight = index === 0 || index === sentenceCount - 1 ? 1.5 : 1;
    const diversityWeight = 1 + uniqueKeywordCount * 0.8;

    return totalKeywordCount * positionWeight * diversityWeight;
}

/**
 * Zählt die häufigsten Nomen im Text mithilfe der NLP-Bibliothek.
 * @param {string} text - Der zu analysierende Text.
 * @returns {Object} - Häufigste Nomen und deren Frequenzen.
 */
function countNouns(text) {
    const doc = compromise(text);
    const nounFrequency = {};

    doc.nouns().out('array').forEach(noun => {
        const normalizedNoun = noun.toLowerCase();
        nounFrequency[normalizedNoun] = (nounFrequency[normalizedNoun] || 0) + 1;
    });

    return Object.entries(nounFrequency)
        .filter(([noun]) => Object.values(topicKeywords).flat().some(keyword => noun.includes(keyword.toLowerCase())))
        .sort((a, b) => b[1] - a[1])
        .slice(0, 3)
        .reduce((acc, [noun, freq]) => ({ ...acc, [noun]: freq }), {});
}

/**
 * Zählt die Anzahl der Nomen, Verben und Adjektive im Text mithilfe der NLP-Bibliothek.
 * @param {string} text - Der zu analysierende Text.
 * @returns {Object} - Enthält die Anzahl der Nomen, Verben und Adjektive.
 */
function countWordTypes(text) {
    const doc = compromise(text);
    const nounCount = doc.nouns().out('array').length;
    const verbCount = doc.verbs().out('array').length;
    const adjectiveCount = doc.adjectives().out('array').length;

    return {
        nouns: nounCount,
        verbs: verbCount,
        adjectives: adjectiveCount
    };
}
