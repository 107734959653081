// App.js
import React, { useState } from 'react';
import './App.css';
import TextInput from './components/TextInput';
import SummaryOutput from './components/SummaryOutput';
import OCRComponent from './components/OCRComponent';
import FileUploadComponent from './components/FileUploadComponent'; // Datei-Upload-Komponente
import { useAnalyzeText } from './components/algorithmus'; // Import the React hook

function App() {
    const [text, setText] = useState('');
    const [cameraActive, setCameraActive] = useState(false); // Define cameraActive state
    const analysis = useAnalyzeText(text); // Use the hook directly to access the analysis

    const handleTextChange = (inputText) => {
        setText(inputText);
    };

    const handleTextExtracted = (extractedText) => {
        setText(extractedText);
    };

    const clearText = () => {
        setText('');
    };

    return (
        <div className="app-container">
            <h1>Text Analyzer</h1>
            <TextInput text={text} onTextChange={handleTextChange} />

            <div style={{ marginBottom: '20px', textAlign: 'center' }}>
                <button onClick={() => setCameraActive(!cameraActive)} className="pdf-button">
                    {cameraActive ? 'Kamera deaktivieren' : 'Kamera aktivieren'}
                </button>
            </div>

            {cameraActive && <OCRComponent onTextExtracted={handleTextExtracted} />} {/* Kamera-Komponente */}
            
            {/* Datei-Upload-Komponente für Dokumente/Screenshots */}
            <FileUploadComponent onTextExtracted={handleTextExtracted} />

            <SummaryOutput
                text={text}
                summary={analysis.summary} // Updated to use summary from analysis
                nouns={analysis.nouns}     // Updated to use nouns from analysis
                verbs={analysis.verbs}     // Updated to use verbs from analysis
                adjectives={analysis.adjectives} // Updated to use adjectives from analysis
                clearText={clearText}
            />
        </div>
    );
}

export default App;
